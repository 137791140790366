import { ButtonHTMLAttributes, forwardRef, memo, MouseEvent, Ref, useCallback, useMemo } from 'react'
import { Link, LinkProps, useNavigate } from '@remix-run/react'

export interface BaseButtonProps extends Partial<LinkProps> {
  subject?: string
  disabled?: boolean
}

export interface ViewProps extends BaseButtonProps {}

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLElement, ViewProps>(
  ({ className, to, subject, disabled, children, onClick, ...props }, ref) => {
    const navigate = useNavigate()
    const pathname = useMemo(() => (typeof to === 'string' ? to : to?.pathname || ''), [to])
    const routed = useMemo(
      () => (pathname?.startsWith('#') || pathname?.startsWith('/')) && !props.download,
      [pathname, props.download]
    )

    const handleClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        onClick?.(e as unknown as MouseEvent<HTMLAnchorElement>)
        if (routed) {
          e.preventDefault()
          navigate(pathname)
        }
      },
      [navigate, onClick, pathname, routed]
    )

    return to ? (
      <Link to={to} ref={ref as Ref<HTMLAnchorElement>} className={className} {...props} onClick={handleClick}>
        {children}
      </Link>
    ) : (
      <button
        ref={ref as Ref<HTMLButtonElement>}
        className={className}
        disabled={disabled}
        onClick={handleClick}
        {...(props as unknown as ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        {children}
      </button>
    )
  }
)

View.displayName = 'BaseButton-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const BaseButton = forwardRef<HTMLElement, BaseButtonProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

BaseButton.displayName = 'BaseButton'

export default memo(BaseButton)
